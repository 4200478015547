import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_nyaskosnoren/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import Image from "../components/image.js";
export const query = graphql`
  query {
    file(relativePath: { eq: "knytaskosnoren-300x61.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 300) {
          base64
          aspectRatio
          width
          height
          src
          srcSet
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "knyta-skosnören"
    }}>{`Knyta Skosnören`}</h1>
    <p>{`Vill du knyta skosnören har du hittat rätt. När många söker online efter
olika sätt att knyta sina skor så menar de ofta snöra istället. Med att
knyta menar vi själva knuten, dvs det du gör av de två ändarna av
skosnöret. Med snöra menar vi vad man gör med hela skosnöret och hur man
leder de genom de olika öglorna på skorna.`}</p>
    <p>{`Är du på jakt efter nya skosnören? Hoppa då över till vår sida `}<Link to="/kopa-skosnoren-online/" mdxType="Link">{`Köpa Skosnören Online`}</Link>{`.`}</p>
    <p>{`Genom att knyta dina skor på ett annat sätt kan du få ett nytt utseende på
dina skor, utan att behöva köpa nya skosnören online eller i butik. En
annan knut på dina skosnören kan även hålla bättre och inte gå upp lika
lätt. Detta kan vara extra användbart om du till exempel använder vaxade
eller syntetiska skosnören som tenderar att gå upp lite lättare en vanliga
bomullssnören.`}</p>
    <p>{`Nedan följer ett antal beskrivningar på ett antal vanliga sätt att knyta
skosnören. Tyvärr har vi inga bilder på hur man kan knyta skosnören men vi
rekommenderar Ians Shoelaces som är en mycket informativ sajt online där
du hittar all information som du någonsin kommer att behöva angående
skosnören. På `}<a href="http://www.fieggen.com/shoelace/knots.htm">{`Ians Shoelaces`}</a>{`{" "}
hittar du också bra länkar till sidor där du kan köpa skosnören online i
utländska butiker.`}</p>
    <h2 {...{
      "id": "går-din-knut-ofta-upp"
    }}>{`Går din knut ofta upp?`}</h2>
    <p>{`Ett vanligt problem, som vi tror att alla någon gång har haft, är att
vissa skor och skosnören tenderar att gå upp väldigt lätt. Vi listar här
tre åtgärder som vi tycker brukar hjälpa.`}</p>
    <h3 {...{
      "id": "1-knyt-mer-dubbelknut"
    }}>{`1. Knyt mer Dubbelknut`}</h3>
    <p>{`Den absolut enklaste åtgärden är att helt enkelt knyta med dubbelknut
istället för enkelknut. Detta är ett nästintill 100%-igt skydd mot att
snörena går upp och rekommenderas på till exempel träningsskor.`}</p>
    <h3 {...{
      "id": "2-använd-runda-skosnören-istället-för-platta"
    }}>{`2. Använd runda skosnören istället för platta`}</h3>
    <p>{`Runda skosnören är mer hållbara än platta vilket gör att du kan "dra åt"
knuten mycket mer och den håller då mycket bättre. Kika in på vår sida för `}<Link to="/kopa-skosnoren-online/platta-skosnoren/" mdxType="Link">{`Platta Skosnören`}</Link>{` för att hitta ett par som passar dig och dina skor.`}</p>
    <h3 {...{
      "id": "3-använd-vaxade-skosnören"
    }}>{`3. Använd vaxade skosnören`}</h3>
    <p>{`Vaxade skosnören har en yta som fäster mycket bättre och därmed gör att
knutar håller mycket bättre. Kika in på vår sida för `}<Link to="/kopa-skosnoren-online/vaxade-skosnoren/" mdxType="Link">{`Vaxade Skosnören`}</Link>{` för att hitta ett par som passar dig och dina skor.`}</p>
    <h2 {...{
      "id": "olika-sätt-att-knyta-skosnören"
    }}>{`Olika sätt att knyta skosnören`}</h2>
    <h3 {...{
      "id": "standardknut"
    }}>{`Standardknut`}</h3>
    <ol>
      <li parentName="ol">{`Börja med en startknut, vilket man måste kunna om man ska knyta
skosnören, genom att föra ena änden över och sedan under det andra
snöret. Detta är en klassisk start-knut som de flesta knutar börjar med.`}</li>
      <li parentName="ol">{`Gör en loop av ena änden och snurra den andra runt denna. Detta kommer
att skapa ett hål mellan de två snörena, här drar du igenom det snoret
som du snurrade runt loopen vilket skapar en ny loop. Dra sedan i de två
öglorna och du är nu klar`}</li>
    </ol>
    <h3 {...{
      "id": "knut-med-två-öglor"
    }}>{`Knut med två öglor`}</h3>
    <ol>
      <li parentName="ol">{`Börja med knuten som du nästan alltid gör när du ska knyta skosnören,
precis som tidigare`}</li>
      <li parentName="ol">{`Gör en ögla av varje ände med hjälp av dessa två så gör du sedan en ny
startknut`}</li>
    </ol>
    <Image fixed={props.data.file.childImageSharp.fixed} alt="Ett exempel på hur du kan knyta skosnören med två ögglor." title="Ett exempel på hur du kan knyta skosnören med två ögglor." rowOne="Skapare: OCAD U" rowTwo="Källa: http://commons.wikimedia.org/wiki/File:Tie_shoelace.png?uselang=sv" mdxType="Image" />
    <h3 {...{
      "id": "kirurgknut"
    }}>{`Kirurgknut`}</h3>
    <ol>
      <li parentName="ol">{`Gör båda stegen i standard knuten, men spänn inte åt knuten i slutet`}</li>
      <li parentName="ol">{`Innan du spänner så drar du ena loopen ett par varje runt den andra så
att den blir som en spiral, spänn sedan knuten`}</li>
    </ol>
    <h3 {...{
      "id": "dubbelknut"
    }}>{`Dubbelknut`}</h3>
    <ol>
      <li parentName="ol">{`Gör både steg ett och två i standardknute`}</li>
      <li parentName="ol">{`De två öglorna som du nu har kvar gör du en startknut av`}</li>
      <li parentName="ol">{`Denna knut används ofta för att säkra skosnörena på skorna extra mycket.
Denna knut är även perfekt om du köpt skosnören online och inte vill
tappa dem första dagen de används`}</li>
    </ol>
    <h2 {...{
      "id": "snöra-skor"
    }}>{`Snöra skor`}</h2>
    <p>{`När vi pratar om att snöra skor så är det hur vi drar skosnöret genom de
olika öglorna på skorna. Genom att snöra om skorna med befintliga
skosnören kan man få ett helt nytt utseende och ge nytt liv år dina skor.
För ännu större effekt rekommenderar vi givetvis att du köper nya
skosnören, antingen online eller i butik. Störst effekt får man givetvis
om skosnörena och skorna skiljer väldigt mycket i färg, då snörningen syns
maximalt i dessa fall.`}</p>
    <p>{`Det finns flera olika sätt att snöra dina skor. De flesta skor är snörade
lika dant vid köp vilket är den klassiska vanliga snörningen. Vill du
förändra dina skor utan att köpa nya skosnören är vårt tips att du kollar
runt här och för att sedan kunna snöra skor på ett nytt sätt.`}</p>
    <p>{`En sida vi tycker är bra är `}<a href="http://www.wikihow.com/Lace-Shoes">{`Wiki How-Lace Shoes`}</a>{` där du
kan få hjälp med mängder av frågor.`}</p>
    <h3 {...{
      "id": "varför-snöra-skor-på-ett-nytt-sätt"
    }}>{`Varför snöra skor på ett nytt sätt?`}</h3>
    <p>{`Genom att snöra skor på ett annat sätt kan du både få visuell och praktisk
effekt. De visuella effekterna är ganska självklara men de praktiskt kan
vara mindre självklara. Genom att snöra om skorna kan du sedan knyta dem
så att de sitter tajtare eller lösare.`}</p>
    <h3 {...{
      "id": "extra-hål-på-löparskor"
    }}>{`Extra hål på löparskor`}</h3>
    <p>{`Högst upp på löparskor finns ofta ett par extra snörhål som används för
att snöra skorna, men få vet vad dessa är till för och hur de används men
nu ska du få svaret. Dessa används för att säkra foten ännu bättre i skon
så att den hålls på plats extra bra utan att man ska behöva spänna snörena
för hårt. Detta är extra användbart om man har smala fötter och/eller höga
vrister. Vill du lära dig hur man knyter skorna med hjälp av de extra
hålen kan vi rekommendera `}<a href="http://iform.se/traning/lopning/knyt-loparskorna-ratt">{`iForm`}</a>{` som har en utmärkt guide för hur du på bästa sätt snöra dina löparskor.
Anledningen till att dessa hål återfinns på löparskor är för att det vid
löpning är extra viktigt att foten sitter still i skon för att förhindra
skoskav och andra skador.`}</p>
    <h3 {...{
      "id": "skador-och-slitningar"
    }}>{`Skador och slitningar`}</h3>
    <p>{`Över tid så slits skosnören ut och kommer att behöva bytas ut. Vissa
skosnören kan hålla lite längre, till exempel skosnören i läder, men då
dessa ofta är betydligt dyrare kan det löna sig att köpa lite billigare
syntetiska skosnören istället, trots att de inte kommer hålla lika länge.
En väldigt vanlig orsak till att skosnörena slits ut är att man knyter
skorna hårt. Detta då man utsätter dem för stora spänningar dels vid
själva knytningen men också vid användandet. För att förhindra detta kan
man tänka på att inte snöra sina skor så hårt.`}</p>
    <p>{`En annan orsak är att öglorna i skorna kan ha slitits ut. Dessa är ofta i
metall eller plast och när dess börjar bli gamla och slitna så kan det
uppstå vassa kanter. Dessa vassa kanter skaver sedan mot skosnörena vilket
gör att dessa slits ut. Detta är en av anledningarna till att skosnören
slits fortare och fortare på ett par skor.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      